<template>
  <div class="w-full flex justify-between">
    <h2 class="text-lg font-medium truncate mr-5 capitalize">
      Indicadores de Operación
    </h2>
    <!-- <div
      class="sm:ml-auto mt-3 sm:mt-0 relative text-gray-700 dark:text-gray-300"
      style="display:flex;"
    >
      <div class="flex align-baseline mr-2">
        <CalendarIcon class="my-auto inset-y-0 ml-3 left-0" size="36" />
      </div>
      <div class="border p-2">
        <div style="width: auto; display: flex;">
          <div class="mr-2">
            <label for="filterYear">Año</label>
            <select
              id="filterYear"
              class="form-control form-control-sm"
              v-model="anio"
              @change="onChangeAnio"
            >
              <option v-for="(row,k) in anios" :key="k" :value="row">{{row}}</option>
            </select>
          </div>
          <div class="mr-2">
            <label for="">Mes</label>
            <select
              v-model="mes"
              class="form-control form-control-sm"
              @change="onChangeMes"
            >
              <option v-for="(row,k) in meses" :key="k" :value="row.id">{{row.nombre}}</option>
            </select>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>
<script>
import storeFiltro from './store/filtro.store'
import { computed, onMounted, ref } from 'vue'
import dayjs from 'dayjs'
export default {
  name: 'filtro',
  setup () {
    /** data **/
    const meses = ref([
      {
        id: 0,
        nombre: 'Enero'
      },
      {
        id: 1,
        nombre: 'Febrero'
      },
      {
        id: 2,
        nombre: 'Marzo'
      },
      {
        id: 3,
        nombre: 'Abril'
      },
      {
        id: 4,
        nombre: 'Mayo'
      },
      {
        id: 5,
        nombre: 'Junio'
      },
      {
        id: 6,
        nombre: 'Julio'
      },
      {
        id: 7,
        nombre: 'Agosto'
      },
      {
        id: 8,
        nombre: 'Septiembre'
      },
      {
        id: 9,
        nombre: 'Octubre'
      },
      {
        id: 10,
        nombre: 'Noviembre'
      },
      {
        id: 11,
        nombre: 'Diciembre'
      }
    ])
    const anio = ref(dayjs().year())
    const mes = ref(dayjs().month())
    /** computed **/
    const anios = computed(() => {
      const years = []
      const yearNow = new Date().getFullYear()
      for (let index = 0; index < 5; index++) {
        years.push(yearNow - index)
      }
      return years
    })
    const _anio = computed(() => storeFiltro.getters.anio)
    const _mes = computed(() => storeFiltro.getters.mes)
    /** methods **/
    const onChangeMes = ({ target }) => {
      storeFiltro.commit('setMes', target.value)
    }
    const onChangeAnio = ({ target }) => {
      storeFiltro.commit('setAnio', target.value)
    }
    /** hooks **/
    onMounted(() => {
      console.log(_anio, _mes)
    })
    return {
      anios,
      meses,
      onChangeMes,
      onChangeAnio,
      anio,
      mes
    }
  }
}
</script>
<style scoped>

</style>
